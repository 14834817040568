<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto" label-position="top" ref='form_' class="fromClass"
            :rules='rules'>
                    <el-form-item :label="$t('currencySet.name1')" prop="userId">
                        <el-select v-model="fromValue.userId" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                            <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name2')" prop="appId">
                        <el-select v-model="fromValue.appId" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                            <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'  />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('news.name3')"  prop="allCoin">
                        <el-input v-model="fromValue.allCoin"  :placeholder="$t('inputTit.xuanze')" disabled>
                            <template #append v-if='fromValue.userId && fromValue.appId'>
                                <el-button text type="primary" @click="chooseCoin1(1)">{{$t('button.checks')}}</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('otherWallatNft.text3')" prop="allCoin">
                        <el-radio-group v-model="fromValue.isWallet" size="large" @change="changeFun_">
                            <el-radio-button :label="1">{{$t('otherWallatNft.text4')}}</el-radio-button>
                            <el-radio-button :label="2">{{$t('otherWallatNft.text5')}}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if='fromValue.isWallet === 2' :label="$t('otherWallatNft.text6')" prop="walletAddress"  :rules="rules_">
                        <el-input v-model="fromValue.walletAddress" disabled  :placeholder="$t('inputTit.xuanze')">
                            <template #append v-if='fromValue.userId && fromValue.appId && fromValue.chain'>
                                <el-button text type="primary" @click="chooseCoin1(2)">{{$t('button.checks')}}</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('otherWallatNft.name1')" prop="walletName">
                        <el-input v-model="fromValue.walletName" :placeholder="$t('inputTit.shuru')" :disabled='fromValue.isWallet === 2?true:false'/>
                    </el-form-item>
                    <el-form-item :label="$t('otherWallatNft.name2')" prop="priority">
                        <el-input v-model="fromValue.priority" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySetNft.name29')" prop="googleCode">
                        <el-input v-model="fromValue.googleCode"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
            <el-row :gutter="20" justify="end">
                <!-- <el-col :span="5" >
                    <el-button @click="emit('successFun')">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.compla')}}</el-button>
                </el-col> -->
            </el-row>
        </el-form>
        <el-dialog v-model="innerVisible1" width="750" :title="txtArray[dialogStyle]" append-to-body >
            <protocolView v-if='dialogStyle === 1' @getCoin='getCoin1' ref='protocolView_'/>
            <addrView v-else @getCoin='getCoin1' ref='addrView_' />
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,reactive,defineEmits,getCurrentInstance,defineExpose,nextTick } from 'vue'
    import { userStore } from '@/store/user'
    import addrView from './addr.vue'
    import protocolView from './coin.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import apiList from '@/const/apiList'
    const { $t } = getCurrentInstance().proxy;
    
    const fromValue = reactive({ 
        userId: '',userType: 2,tenantId:'',
        appId:'',
        allCoin:'',chain:'',coin:'',
        isWallet: 1,
        walletAddress:'',walletName:'',priority:1,
        googleCode:'',
        protocolType: 1,
        walletType:5,

    })

    //初始化是平台还是商家
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.userId = userStore().getUserInfo[0].tenantId
    //初始化商家和app数组
    const tenantArray = ref([])
    const appArray = ref([])
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;})
    //链，协议；地址参数
    const innerVisible1 = ref(false)
    const dialogStyle = ref(0)
    const txtArray = ['',$t('news.name3'),$t('nfts.text2')]
    // fromValue
    const emit = defineEmits(['successFun'])
    const form_ = ref(null)
    const rules = ref(null);rules.value = addRules.mainRules
    const rules_ = ref([])
    const protocolView_ = ref(null)
    const addrView_ = ref(null)
    
    const changeFun_ = (e)=>{
        if(e === 2){
            rules_.value = [{ required: true, message: $t('inputTit.xuanze'), trigger: 'blur' }]
        }else{
            fromValue.walletAddress = '';
            rules_.value = []
        }
    }
    const chooseCoin1 = (num)=>{
        dialogStyle.value = num
        innerVisible1.value = true
        nextTick(()=>{
            if(num === 1){
                protocolView_.value.init_({
                    appId: fromValue.appId,
                    tenantId: fromValue.userId,
                    isBaseCoin: 1,
                    userType: 2,
                }) 
            }else{
                addrView_.value.init_({
                    appId: fromValue.appId,
                    tenantId: fromValue.userId,
                    chain: fromValue.chain,
                    coin: fromValue.coin,
                    walletTypes: '4,5',
                })
            }
        })
    }
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                fromValue.tenantId = fromValue.userId
                let obj_ = JSON.parse(JSON.stringify(fromValue))
                delete obj_.allCoin
                delete obj_.googleCode
                Api_.addAssetswalletinfoStatus(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        })
    }
    const getCoin1 = (obj)=>{
        innerVisible1.value = false
        if(dialogStyle.value === 1){
            fromValue.allCoin = obj.chain + '/' + obj.coin
            fromValue.chain = obj.chain
            fromValue.coin = obj.coin
        }else{
            fromValue.walletAddress = obj.walletAddress;
            fromValue.walletName = obj.walletName;
            fromValue.coin = obj.coin;
        }
    }
    const init_ = ()=>{
        for(let i in fromValue){
            fromValue[i] = ''
        }
        fromValue.userType = 2;
        fromValue.isWallet = 1;
        fromValue.priority = 1;
        fromValue.protocolType = 1;
        fromValue.walletType = 5;
    }
    defineExpose({completeFun,init_})
</script>
<style lang="scss" scoped>
    
    :deep(.avue-form__group .el-col) {
        display: flex;
    }

</style>